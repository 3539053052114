export default grapesjs.plugins.add('cc-product-slider', (editor, options) => {
	editor.BlockManager.add(
		'ProductSlider', {
			category: 'Commerce',
			label: 'Product Slider',
			attributes: { class: 'fa fa-barcode' },
			content: `<div class="cc-dynamic-product-slider hdm-bg-secondary w-100" data-gjs-type="cc-product-slider" data-initial-state="true">Configure the Settings for the Product Slider</div>`
		}
	);

	// Get DomComponents module
	var comps = editor.DomComponents;
	
	// Get the model and the view from the default Component type
	var defaultType = comps.getType('default');
	var defaultModel = defaultType.model;
	var defaultView = defaultType.view;

	// The `input` will be the Component type ID
	comps.addType('cc-product-slider', {
		// Define the Model
		model: defaultModel.extend({
			// Extend default properties
			defaults: Object.assign(
				{},
				defaultModel.prototype.defaults, {
					// Can't drop other elements inside it
					droppable: false,
					// Traits (Settings)
					traits: [
						{
							type: 'select', // Type of the trait
							label: 'configured Produtlists', // The label you will see in Settings
							name: 'productList', // The name of the attribute/property to use on component
							options: CCRZ.ccCustomContentData.ProductSlider
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible From', // The label you will see in Settings
							name: 'cccStartDate', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible To', // The label you will see in Settings
							name: 'cccEndDate', // The name of the attribute/property to use on component
						}
					],
					attributes: {
						cccStartDate: '',
						cccEndDate: ''
					}
				}
			),
			init() {
				this.on('change:attributes:productList', this.handleTraitChange);
				this.on('change:attributes:cccStartDate', this.handleTraitChange);
				this.on('change:attributes:cccEndDate', this.handleTraitChange);
			},
			handleTraitChange() {
				this.attributes.content = this.generateHTML(false, true);
				this.view.el.innerHTML = this.generateHTML(false, true);
				$(this.view.el).removeAttr('data-initial-state');
				delete this.view.attr['data-initial-state'];
				delete this.attributes.attributes['data-initial-state'];
				let evt = new CustomEvent(
					'renderProductSliderTemplate',
					{
						detail: {
							render: $(this.view.el).find('script')[0],
							anchor: $(this.view.el).find('div')[0],
							domElement: this.view.el
						}
					}
				);
				window.dispatchEvent(evt);
			},
			toHTML: function () {
				return this.generateHTML(true, true);
			},
			generateHTML(includeParentDOMElement, outputAsDOMElement) {
				if (typeof includeParentDOMElement == 'undefined') {
					includeParentDOMElement = false;
				}

				if (typeof outputAsDOMElement == 'undefined') {
					outputAsDOMElement = false;
				}

				let elementString = '';
				let timedAttributes = '';
				let refString = 'renderProductSliderTemplate-' + this.cid;

				if (includeParentDOMElement) {
					if (this.getAttributes().cccStartDate && this.getAttributes().cccEndDate) {
						timedAttributes = ' cccStartDate="' + this.getAttributes().cccStartDate + '" cccEndDate="' +  this.getAttributes().cccEndDate + '"';
					}
					elementString = elementString + '<div class="cc-dynamic-product-slider hdm-bg-secondary product-slider__frame w-100" data-gjs-type="cc-product-slider"' + timedAttributes + '>';
				}

				let renderTemplateTag = 'div';

				if (outputAsDOMElement) {
					renderTemplateTag = 'script';
				}
				elementString = elementString + '<' + renderTemplateTag + ' data-render-template="' + refString + '" id="' + refString + '" type="text/template" >';

				elementString += `  <div class="hdm-bg-secondary">
						<div class="container product-slider">
							<div class="row">
								<div class="col-12">
									<div class="headline mt-60">{{pageLabelMap this.SliderData.ProductSlider_Header_Text}}
									</div>
								</div>
								<div id="slickProductSlider" class="col-12">
									{{#each this.Products}}
										<div class="product-tile pb-4"
											data-gtm-element-id="slider_productListItem_{{#if this.sfid}}{{this.sfid}}{{else}}{{this.SKU}}{{/if}}"
											data-gtm-view-event="hdm.view_item_list"
											data-gtm-view-field-ga4EventName="/ga4_event_name"
											data-gtm-view-value-ga4EventName="view_item_list"
											{{#if this.SKU_SSU_Formatted__c}}
												data-gtm-view-field-id="/ecommerce/items/0/item_id"
												data-gtm-view-value-id="{{this.SKU_SSU_Formatted__c}}"
											{{/if}}
											data-gtm-view-field-price="/ecommerce/items/0/price"
											data-gtm-view-value-price="{{gtmGetPrice this.price}}"
											data-gtm-view-field-eventType="/event_type"
											data-gtm-view-value-eventType="ecommerce"
											data-gtm-view-field-isquote="/ecommerce/is_quote"
											data-gtm-view-value-isquote="{{gtmIsQuote this.price}}"
											data-gtm-view-field-pricingtype="/ecommerce/items/0/price_type"
											data-gtm-view-value-pricingtype="{{gtmGetPriceType this.price}}"
											data-gtm-view-field-itemName="/ecommerce/items/0/item_name"
											data-gtm-view-value-itemName="{{#if this.alternateName}}{{this.alternateName}}{{else}}{{this.sfdcName}}{{/if}}"
											data-gtm-view-field-index="/ecommerce/items/0/index"
											data-gtm-view-value-index="{{gtmGetIndex}}"
											data-gtm-view-field-quantity="/ecommerce/items/0/quantity"
											data-gtm-view-value-quantity="1"
											data-gtm-view-field-itemCategory1="/ecommerce/items/0/item_category"
											data-gtm-view-value-itemCategory1="{{gtmGetBreadcrumbItemByIndex 0}}"
											data-gtm-view-field-itemCategory2="/ecommerce/items/0/item_category2"
											data-gtm-view-value-itemCategory2="{{gtmGetBreadcrumbItemByIndex 1}}"
											data-gtm-view-field-itemCategory3="/ecommerce/items/0/item_category3"
											data-gtm-view-value-itemCategory3="{{gtmGetBreadcrumbItemByIndex 2}}"
											data-gtm-view-field-itemCategory4="/ecommerce/items/0/item_category4"
											data-gtm-view-value-itemCategory4="{{gtmGetBreadcrumbItemByIndex 3}}"
											data-gtm-view-field-itemCategory5="/ecommerce/items/0/item_category5"
											data-gtm-view-value-itemCategory5="{{gtmGetBreadcrumbItemByIndex 4}}"
											data-gtm-view-field-itemListId="/ecommerce/items/0/item_list_id"
											data-gtm-view-value-itemListId="product_slider"
											data-gtm-view-field-itemListName="/ecommerce/items/0/item_list_name"
											data-gtm-view-value-itemListName="Product Slider"
											data-coveo-view-field-sku="/sku"
											data-coveo-view-value-sku="{{this.SKU}}"
										>
											<div class="row ml-0">
												{{#ifNotEquals this.productType "Aggregated"}}	
													{{#if this.HDMNewUntil}}
														{{#if this.TeaserLabel}}
															{{checkNewFlag this.HDMNewUntil this.TeaserLabel ""}}
														{{else}}
															{{checkNewFlag this.HDMNewUntil "" ""}}
														{{/if}}
													{{/if}}
												{{/ifNotEquals}}
												<div class="col-12 product-tile__pictureFrame">
													<!-- {{#if EProductMediasS.[0]}} -->
														{{displayImage this "product-tile__image d-block mx-auto my-4 d-lg-inline cc_product_name_img" src=this.EProductMediasS.[0].URI alt=this.sfdcName dataId=this.SKU width="150"}}
													<!-- {{/if}} -->
													{{#ifNotEquals this.productType "Aggregated"}}
														<div class="product__badgeBox product__badgeBox--tile product__badgeBox--listView badge-icon-position-right">
															{{#ifEquals this.HDMSaphiraEco "1"}}
																<i class="icon-eco d-block mb-3" title="{{pageLabelMap "ProductList_SaphireEco"}}"></i>
															{{/ifEquals}}
															{{#ifEquals this.HDMServiceTechnicianRequired "1"}}
																<i class="icon-service_technican d-block mb-3" title="{{pageLabelMap "ProductList_TechnicianRequired"}}"></i>
															{{else}}
																{{#ifEquals this.HDMProductType "ProductTypeLOV-102305"}}
																	<i class="icon-repair d-block mb-3" title="{{pageLabelMap "ProductList_SpareParts"}}"></i>
																{{/ifEquals}}
															{{/ifEquals}}
														</div>
													{{/ifNotEquals}}
												</div>

												<div class="col-12 product-tile__descBox">
													<p class="h5 product-tile__name mb-0">
														{{#if this.alternateName}}
															{{this.alternateName}}
														{{else}}
															{{this.sfdcName}}
														{{/if}}
													</p>
													<p class="product-tile__SKU">
														{{#if this.SKU_SSU_Formatted__c}}
															{{pageLabelMap "ProductList_SKU"}} {{this.SKU_SSU_Formatted__c}}
														{{/if}}
													</p>
												</div>

												<div class="col-12">
													
													<div class="product-tile__pricebox">

													<p class=" product-tile__pricebox product-tile__pricebox__price">
														{{#if this.price}}
															{{hdmPrice  this.price}}
														{{/if}}
													</p>

													<p class="product-tile__pricebox product-tile__pricebox__unit">
														/PCE
													</p>

													<p class="product-tile__variants mb-0">
														<!-- Auskommentiert, da es für den GoLive USA nicht relevant ist -->
														&nbsp;
														<!-- Available in 3 variants -->
													</p>
												</div>
												</div>
												<div class="col-12">
													{{productLink this "btn btn-primary btn-lg mb-0" text="More details"}}
												</div>
											</div>
										</div>
									{{/each}}
								</div>
							</div>
						</div>
					</div>
				</div>`;

				elementString = elementString + '</' + renderTemplateTag + '>';
				elementString = elementString + '<div data-render-template="' + refString + '" class="dynamicSliderAnchor ' + refString + '" data-product-slider="' + this.getAttributes().productList + '"></div>';
				if (includeParentDOMElement) {
					elementString = elementString + '</div>';
				}

				return elementString;
			}
		},
			{
				isComponent(el) {
					if (el.getAttribute && el.getAttribute('data-gjs-type') == 'cc-product-slider') {
						return {
							type: 'cc-product-slider'
						};
					}
				}
			}
		),
		view: defaultType.view.extend({
			// The render() should return 'this'
			render: function () {
				// Extend the original render method
				defaultType.view.prototype.render.apply(this, arguments);
				return this;
			},
			onRender() {
				let el = this.el;
				if (el.getAttribute) {
					if (el.getAttribute('data-initial-state') != 'true') {
						var evt = new CustomEvent(
							'renderProductSliderTemplate',
							{
								detail: {
									render: $(el).find('script'),
									anchor: $(el).find('div'),
									domElement: el
								}
							}
						);
						window.dispatchEvent(evt);
					}
				}
			}
		})
	});
});